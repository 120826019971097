import { Box, Button, ClickAwayListener, Dialog, Grid, Hidden, IconButton, Stack, Tooltip, Typography, useMediaQuery } from '@mui/material';
import axios from 'axios';
import React, { useCallback, useMemo, useRef, useState } from 'react'
import { API, GOOGLE_MAP } from '../../utils/Api';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { QRCodeSVG } from 'qrcode.react';
import Images from '../../utils/Images';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { setData } from '../../store/Cart/cartSlice';
import { Player } from '@lottiefiles/react-lottie-player';
import { FmdGood, HomeRounded, InfoOutlined, LocationOnOutlined, VerifiedOutlined, WorkOutlineOutlined, WorkRounded } from '@mui/icons-material';
import ReviewScreen from './ReviewScreen';
import { GoogleMap, LoadScript, MarkerF } from '@react-google-maps/api';

const OrderHistoryScreen = () => {
    const token = localStorage.getItem("token");
    const [orderHistory, setOrderHistory] = useState([]);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [orderDetailsModal, setOrderDetailsModal] = useState(false);
    const [reviewModal, setReviewModal] = useState(false);
    const [orderDetailData, setOrderDetailData] = useState([]);
    const selectedOutlet = localStorage.getItem("selectedStoreData");
    const deliveryType = localStorage.getItem("selectedOrderType");
    const outletObject = JSON.parse(selectedOutlet);
    const outletId = outletObject?._id;
    const [loadingOrders, setLoadingOrders] = useState({});
    const [outletData, setOutletData] = useState(JSON.parse(localStorage.getItem('selectedStoreData')));
    const activePickTime = useSelector(state => state?.time?.activePickTime);
    const matchesMdUp = useMediaQuery(theme => theme.breakpoints.up('md'));
    const [selectedOrder, setSelectedOrder] = useState(null);
    const mapRef = useRef(null);
    const [outletMarker, setOutletMarker] = useState(Images.xpanseMapMarker);
    const [currentCoordinates, setCurrentCoordinates] = useState({ lat: 0, lng: 0 });
    const [outlet, setOutlet] = useState({ lat: 0, lng: 0 });
    const orderStatus = orderDetailData?.order?.orderStatus;
    const gridMd = (orderStatus === "DELIVERED" || orderStatus === "CANCELLED") ? 12 : 8;

    const containerStyle = {
        width: "95%",
        height: "65%",
        marginTop: "8%",
        border: "4px solid #fff",
        borderRadius: "12px"
    };

    const handleOpenReviewModal = () => {
        setReviewModal(true);
    }

    const handleCloseReviewModal = () => {
        setReviewModal(false);
    }

    const handleOpenOrderDetailsModal = () => {
        setOrderDetailsModal(true);
    }

    const fetchUserCartData = async () => {
      try {
        const response = await axios.get(`${API.BASE_URL}cart/userCart/${outletData._id}/${deliveryType}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        dispatch(setData(response?.data));
        console.log('Cart Screen Data', response?.data);
      } catch (error) {
        dispatch(setData([]));
        console.log('Cart Screen Error', error?.response?.data);
      }
    };

    const handleCloseOrderDetailsModal = () => {
        setOrderDetailsModal(false);
    }

    const getOrders = async () => {
        try {
        const response = await axios.get(`${API.BASE_URL}order/getConsumerOrders/${outletData._id}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
            setOrderHistory(response.data.data.orders);
            console.log("Orders history fetched successfully", orderHistory);
        } catch (error) {
            console.error('Error while fetching orders history data:', error);
        }
    };
    
    useEffect(() => {
        getOrders();
        setOutletData(JSON.parse(localStorage.getItem('selectedStoreData')));
    }, [])

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        const dayOfWeek = days[date.getUTCDay()];
        const month = months[date.getUTCMonth()];
        const year = date.getUTCFullYear();
        const day = date.getUTCDate();
        return ` ${day}-${month}-${year}`;
        // return `${dayOfWeek}, ${day} - ${month} - ${year}`;
    };

    const handleNavigateToOrderDetail = (orderDetails) => {
        setOrderDetailData(orderDetails); 
        // dispatch(orderDetails.order.readyTime);
        console.log('aaaaaaaaaaaaaaaa',orderDetails);
        const isDelivery = orderDetails.order.ordersType === 'DELIVERY';
        const targetScreen = isDelivery ? '/orderDetailsDelivery' : '/orderDetails';
        if (window.innerWidth <= 768) {
            navigate(targetScreen, { state: { orderDetails, screenLocation: "ORDERHISTORY" } });
        } else {
            // setOrderDetailsModal(true);
            handleOpenOrderDetailsModal();
        }
    };

    const handleAddToCart = async (orderId, orderItems) => {
        try {
            setLoadingOrders(prevState => ({
                ...prevState,
                [orderId]: true
            }));
            const cartItems = orderItems?.map(item => ({
                product: item?.product?._id,
                quantity: item?.quantity,
            }));
            const requestBody = {
                ordersType: deliveryType,
                outlet: outletId,
                cartItems: cartItems,
            };
            const response = await axios.post(`${API.BASE_URL}cart`, requestBody, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setLoadingOrders(prevState => ({
                ...prevState,
                [orderId]: false
            }));
            toast.success('Product(s) added to cart!!!');
            fetchUserCartData();
            console.log(response.data);
        } catch (error) {
            setLoadingOrders(prevState => ({
                ...prevState,
                [orderId]: false
            }));
            toast.error('Failed to add products with addons');
            console.error(error);
        }
    };

    const handleRateOrder = (order) => {
        // navigate('/reviewScreen', { state: { order } });
        console.log('Review the selected order :', order);
        if (matchesMdUp) {
            setSelectedOrder(order);
            setReviewModal(true);
        } else {
            navigate('/reviewScreen', { state: { order } });
        }
    };

    useEffect(() => {
        let interval;
        const fetchCurrentCoordinates = async () => {
            try {
                const response = await axios.get(`${API.BASE_URL}order/tracking/${orderDetailData?.order?.deliveryOrderId}`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                const { latitude, longitude } = response.data.data.current;
                setCurrentCoordinates({ lat: parseFloat(latitude), lng: parseFloat(longitude) });
                console.log('Successfully fetched current coordinates:', currentCoordinates);
            } catch (error) {
                console.error('Error while fetching current coordinates:', error);
            }
        };
        if (orderDetailData?.order?.deliveryOrderId) {
            fetchCurrentCoordinates();
            interval = setInterval(fetchCurrentCoordinates, 3000);
        }
        return () => clearInterval(interval);
    }, [orderDetailData?.order?.deliveryOrderId]);

    const totalSavings = useMemo(() => {
        return orderDetailData.orderItems?.reduce((acc, item) => {
            const savingsPerItem = (item?.product?.mrp - item?.product?.offerPrice) * item?.quantity;
            const savingsPerAddons = item?.addons?.reduce((addonAcc, addon) => {
            return addonAcc + ((addon?.mrp - addon?.offerPrice) * (item?.quantity || 0));
            }, 0);
            return acc + savingsPerAddons + savingsPerItem;
        }, 0);
    }, [orderDetailData?.orderItems]);

    const totalCouponDiscount = orderDetailData?.orderItems?.reduce((total, item) => {
        return total + (item?.discount);
    }, 0);

    const totalOfferPrice = useMemo(() => {
        return orderDetailData?.orderItems?.reduce((acc, item) => {
            const totalOfferPrice = (item?.product?.offerPrice) * item?.quantity;
            return acc + totalOfferPrice;
        }, 0);
    }, [orderDetailData?.orderItems]);

    const totalSizeUpgradeAmount = useMemo(() => {
        return orderDetailData.orderItems?.reduce((acc, item) => {
            return acc + (item?.sizeUpgradePayload?.newAddonItemOfferPrice || 0) - (item?.sizeUpgradePayload?.prevAddonItemOfferPrice || 0);
        }, 0);
    }, [orderDetailData?.orderItems]);

    const totalTaxAmount = (orderDetailData?.order?.totalTaxAmount || 0) + (orderDetailData?.order?.GSTPackingCharges || 0) +  (orderDetailData?.order?.GSTPlatformFees || 0);
    const totalSum = totalTaxAmount + totalOfferPrice;
    const formattedSum = totalSum?.toFixed(2)?.replace(/\.?0*$/, '');
    const totalQuantityInOrder = orderDetailData?.orderItems?.reduce((total, item) => { return total + item?.quantity; }, 0);

    useEffect(() => {
        const fetchCurrentCoordinates = async () => {
            try {
            const response = await axios.get(`${API.BASE_URL}order/tracking/${orderDetailData?.order?.deliveryOrderId}`, {
                headers: {
                Authorization: `Bearer ${token}`
                }
            });
            setOutlet({ lat: parseFloat(orderDetailData?.order?.outlet?.coordinates?.latitude), lng: parseFloat(orderDetailData?.order?.outlet?.coordinates?.longitude)});
            setOutletMarker(Images.xpanseMapMarker);
            console.log('Successfully fetched current coordinates:', response.data.data);
            } catch (error) {
            console.error('Error while fetching current coordinates:', error);
            }
        };
        fetchCurrentCoordinates();
        const intervalId = setInterval(fetchCurrentCoordinates, 300000);
        return () => {
            clearInterval(intervalId);
        };
    }, [orderDetailData?.order?.deliveryOrderId, token]);

    useEffect(() => {
        const timer = setTimeout(() => {
            setCurrentCoordinates({ lat: parseFloat(orderDetailData?.order?.deliveryDetails?.coordinates?.latitude), lng: parseFloat(orderDetailData?.order?.deliveryDetails?.coordinates?.longitude) });
            setOutlet({ lat: parseFloat(orderDetailData?.order?.outlet?.coordinates?.latitude), lng: parseFloat(orderDetailData?.order?.outlet?.coordinates?.longitude) });
        }, 1000);
        return () => clearTimeout(timer);
    }, [orderDetailData]);

    useEffect(() => {
        if (orderDetailData && orderDetailData?.order?.deliveryDetails?.coordinates && mapRef.current) {
            const bounds = new window.google.maps.LatLngBounds();
            bounds.extend(new window.google.maps.LatLng(currentCoordinates?.lat, currentCoordinates?.lng));
            bounds.extend(new window.google.maps.LatLng(outlet?.lat, outlet?.lng));
            mapRef.current.fitBounds(bounds);
        }
    }, [orderDetailsModal, orderDetailData, currentCoordinates, outlet]);

    const onLoad = useCallback((map) => {
        mapRef.current = map;
    }, []);
    
    const onUnmount = useCallback(() => {
        mapRef.current = null;
    }, []);

    const [openTaxTooltip, setTaxOpenTooltip] = useState(false);

    const handleTaxTooltipOpen = () => {
        setTaxOpenTooltip(true);
    };

    const handleTaxTooltipClose = () => {
        setTaxOpenTooltip(false);
    };

    const taxTooltipContent = (
        <Box>
            <Typography variant="body2" padding={'2px 0 2px 0'} sx={{ display: 'flex', justifyContent: 'space-between'}} component="p">
                Base Tax: <span>₹{orderDetailData?.order?.totalTaxAmount}</span>
            </Typography>
            {orderDetailData?.order?.ordersType === 'DELIVERY' && (
                <Typography variant="body2" padding={'2px 0 2px 0'} sx={{ display: 'flex', justifyContent: 'space-between'}} component="p">
                    GST Packing Charges: <span>₹{orderDetailData?.order?.GSTPackingCharges}</span>
                </Typography>
            )}
            <Typography variant="body2" padding={'2px 0 2px 0'} sx={{ display: 'flex', justifyContent: 'space-between'}} component="p">
                GST Platform Fees: <span>₹{orderDetailData?.order?.GSTPlatformFees}</span>
            </Typography>
            <Typography variant="body2" padding={'2px 0 2px 0'} component="p" sx={{ borderTop: '1px solid #fff', display: 'flex', justifyContent: 'space-between' }}>
                Total Tax: <span>₹ {totalTaxAmount?.toFixed(2)}</span>
            </Typography>
        </Box>
    );
    
    const calculateTotalPrice = (item) => {
        let totalPrice = item?.product?.offerPrice * item?.quantity;
        item?.addons?.forEach(addon => {
        totalPrice += addon?.offerPrice * item?.quantity;
        });
        return totalPrice?.toFixed(2).replace(/\.?0*$/, '');
    };
    
    const updateOrderStatus = async () => {
        try {
            const response = await axios.get(`${API.BASE_URL}order/getConsumerOrders/${outletData._id}?_id=${orderDetailData?.order?._id}`,
            {
            headers: {
                'Authorization': `Bearer ${token}`
            }
            });
            setOrderDetailData(response?.data?.data?.orders[0]);
        } catch (error) {
            console.error('Failed to fetch order:', error);
        }
    };
    
    useEffect(() => {
        const intervalId = setInterval(updateOrderStatus, 3000);
        return () => clearInterval(intervalId);
    }, [orderDetailData?.order?._id]);

    return (
        <>
        <Box className="orderHistory">
            <Hidden mdDown>
                <Typography className="title">Order History</Typography>
            </Hidden>
            <Box className='container'>
                {orderHistory.length === 0 ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '60vh' }}>
                        <Player autoplay loop src={Images.emptyCart} className="emptyCart" />
                    </Box>
                ) : (
                orderHistory.map((order) => {
                    const hasEventProduct = order?.orderItems?.some(item => item.product?.isEventProduct);

                    return (
                        <Box key={order._id} className="order-history-card">
                            <Box className="row">
                                <Box onClick={() => handleNavigateToOrderDetail(order)} className='DateAndStatus'>
                                <Typography className='day-date'>
                                    #{order?.order?.order_sequence}{order?.order?.order_no}, {formatDate(order?.order?.createdAt)}
                                </Typography>
                                <Typography
                                    className={
                                    order?.order?.orderStatus === 'DELIVERED'
                                        ? 'status-delivered'
                                        : 'status'
                                    }
                                    sx={{
                                    color:
                                        order?.order?.orderStatus === 'DELIVERED' ? 'inherit' :
                                        order?.order?.orderStatus === 'READY-TO-PICK' ? '#09A5F7' :
                                        order?.order?.orderStatus === 'CONFIRMED' ? '#186B8C' :
                                        order?.order?.orderStatus === 'COMPLETED' ? '#71BAE6' : 'inherit'
                                    }}
                                >
                                    {order?.order?.orderStatus === 'DELIVERED' && (
                                    <VerifiedOutlined sx={{ paddingRight: '5px' }} />
                                    )}
                                    {order?.order?.orderStatus}
                                </Typography>
                                </Box>

                                <Typography onClick={() => handleNavigateToOrderDetail(order)} className='rate'>
                                <Typography className="items">
                                    <Typography className='rupee'>₹{order?.order?.grandTotal?.toFixed(2).replace(/\.?0*$/, '')}</Typography> - {order?.orderItems?.length} items
                                </Typography>
                                <Typography className="productsListing">
                                    {order?.orderItems?.slice(0, 2).map((item, index) => (
                                    <span key={item?._id}>
                                        {item?.product?.name} ({item?.quantity})
                                        {index !== order?.orderItems?.length - 1 && ','}
                                    </span>
                                    ))}
                                    {order?.orderItems?.length > 2 && (
                                    <span onClick={() => handleNavigateToOrderDetail(order)} style={{ color: '#09A5F7', paddingLeft: '4px', cursor: 'pointer', textDecoration: 'underline' }}>
                                        See more
                                    </span>
                                    )}
                                </Typography>
                                </Typography>

                                {order?.order?.orderStatus === 'DELIVERED' && (
                                    <Typography className='buttonDiv'>
                                        {!(outletData?.isEventOutlet || order?.orderItems?.some(item => item?.product?.isEventProduct)) && (
                                            <Button className="reorderButton" onClick={() => handleAddToCart(order?.order?._id, order?.orderItems)}>
                                                {loadingOrders[order?.order?._id] ? 'Ordering...' : 'Reorder'}
                                            </Button>
                                        )}
                                        {!order?.order?.isRated && (
                                            <Button onClick={() => handleRateOrder(order)} className='rateorderButton'>
                                                Rate Order
                                            </Button>
                                        )}
                                    </Typography>
                                )}
                            </Box>
                        </Box>
                    );
                })
                )}
            </Box>
        </Box>
        <Dialog maxWidth className="orderDetailsModal" open={orderDetailsModal} onClose={handleCloseOrderDetailsModal}>
            <Box className="orderDetails">
                <Grid container>
                    {orderDetailData?.order?.orderStatus !== "DELIVERED" && orderDetailData?.order?.orderStatus !== "CANCELLED" && (
                        orderDetailData?.order?.ordersType === "DELIVERY" ? (
                            <Grid item md={4}>
                                <LoadScript googleMapsApiKey={GOOGLE_MAP.GOOGLE_MAP_KEY}>
                                    <Box sx={{ backgroundColor: '#fff', color: '#186B8C', fontWeight: '600', padding: '8px 20px', borderBottomLeftRadius: '20px', borderBottomRightRadius: '20px', width: '120px', display: 'flex', justifyContent: 'center', margin: '0 auto -10px auto' }}>
                                        Order Id: #{orderDetailData?.order?.order_sequence}{orderDetailData?.order?.order_no}
                                    </Box>
                                    <GoogleMap
                                        mapContainerStyle={containerStyle}
                                        onLoad={onLoad}
                                        onUnmount={onUnmount}
                                        center={currentCoordinates}                            
                                        options={{ mapTypeControl: false, zoomControl: false, fullscreenControl: false, keyboardShortcuts: false, streetViewControl: false }}
                                    >
                                        <MarkerF
                                            position={currentCoordinates}
                                            title="Your Location"
                                        />
                                        <MarkerF
                                            position={outlet}
                                            icon={outletMarker}
                                            title="Outlet"
                                        />
                                    </GoogleMap>
                                </LoadScript>
                                <Box className="orderDeliveryDetail">
                                    <Box sx={{ display: 'flex' }}>
                                        <img className="successGifDelivery" src={Images.successTick} alt='successTick' />
                                        <Typography className="titleDelivery">Your order has been placed successfully!</Typography>
                                    </Box>
                                    <Box sx={{ padding: '0 12px 0px 12px'}}>
                                        <Box sx={{ display: 'flex' }}>
                                            <Typography variant='body2' sx={{ fontWeight: '600' }}>Delivering to :</Typography>
                                            {orderDetailData?.order?.deliveryDetails?.type === 'HOME' && <HomeRounded sx={{ color: '#1E9CED' }} />}
                                            {orderDetailData?.order?.deliveryDetails?.type === 'WORK' && <WorkRounded sx={{ color: '#1E9CED' }} />}
                                            {orderDetailData?.order?.deliveryDetails?.type !== 'HOME' && orderDetailData?.order?.deliveryDetails?.type !== 'WORK' && <LocationOnOutlined sx={{ color: '#1E9CED' }} />}
                                            <Typography variant='body2' sx={{ fontWeight: '600' }}>{orderDetailData?.order?.deliveryDetails?.type}</Typography>
                                        </Box>
                                        <Typography variant='body2' >{orderDetailData?.order?.deliveryDetails?.recieverName} {orderDetailData?.order?.deliveryDetails?.addressLine1}, {orderDetailData?.order?.deliveryDetails?.addressLine2}, {orderDetailData?.order?.deliveryDetails?.city}, {orderDetailData?.order?.deliveryDetails?.state}, {orderDetailData?.order?.deliveryDetails?.pincode}</Typography>
                                    </Box>
                                    <img className="couponBorderInverted" src={Images.couponBorderInverted} />
                                </Box>
                            </Grid>
                        ) : (
                            <Grid item md={4}>
                                <Player autoplay loop={false} keepLastFrame={true} src={Images.successGif} className="successGif"></Player>
                                <Box className="qrBox">
                                    <Typography className="orderNo">#{orderDetailData?.order?.order_sequence}{orderDetailData?.order?.order_no}</Typography>
                                    <QRCodeSVG className="qrCode" value={orderDetailData?.order?.scanOrderId} />
                                    <Typography className="scanText">Please Scan The QR Code At Store</Typography>
                                </Box>
                            </Grid>
                        )
                    )}
                    <Grid item md={gridMd}>
                        <Grid container>
                            <Grid item md={12}>
                                {/* {orderDetailData?.order?.orderStatus === "DELIVERED" ? (
                                    <Typography className="placedTitle">Your Order Has Been Delivered Successfully!</Typography>
                                ) : orderDetailData?.order?.orderStatus === "CANCELLED" ? (
                                    <Typography className="placedTitle">Your Order Has Been Cancelled.</Typography>
                                ) : (
                                    <Typography className="placedTitle">Your Order Has Been Placed Successfully!</Typography>
                                )} */}
                                <Typography className="placedTitle">Your Order Status: {orderDetailData?.order?.orderStatus}</Typography>
                            </Grid>
                            <Grid item md={6}>
                                <Box className="marginTop orderFinalSummary">
                                    <img className="couponBorder" src={Images.couponBorder} />
                                    <Box className="orderItemsList" style={{ overflow: 'auto'  }}>
                                        <Typography sx={{ textAlign: 'center', color: '#186B8C', fontWeight: '600' }}>Item Summary</Typography>
                                        {orderDetailData?.orderItems?.map((item, index) => (
                                            <Grid key={item?._id} container className="item" sx={{ borderBottom: index === orderDetailData.orderItems.length - 1 ? 'none' : '1px solid #C5C5C5' }}>
                                                <Grid item className="detail-grid" xs={6}>
                                                    <Typography className="item-name">{item?.product?.name}</Typography>
                                                </Grid>
                                                <Grid item className="detail-grid" xs={3} sx={{ display: 'flex', flexDirection: 'column', textAlign: 'right' }}>
                                                    <Typography className="item-name">Qty - {item?.quantity}</Typography>
                                                </Grid>
                                                <Grid item className="detail-grid" xs={3} sx={{ display: 'flex', flexDirection: 'column', textAlign: 'right' }}>
                                                    <Typography className="item-cost">₹{calculateTotalPrice(item)}</Typography>
                                                </Grid>
                                                <Grid item md={12}>
                                                    {item?.addons?.length > 0 && (
                                                        <Stack flexDirection={'row'}>
                                                            {item.addons.map((addon, index) => (
                                                                <Typography key={index} sx={{ paddingRight: '4px' }} className="item-attribute">
                                                                    {addon.selectedValue}{index === item.addons.length - 1 ? '.' : ', '} 
                                                                </Typography>
                                                            ))}
                                                        </Stack>
                                                    )}
                                                </Grid>
                                            </Grid>
                                        ))}
                                    </Box>
                                </Box>
                                <Box className="addressBox">
                                    <Box className="firstBox">
                                        <Box className="locationIconBox" sx={{ display: 'flex', alignItems: 'center' }}>
                                        <FmdGood className="icon" /> 
                                        {orderDetailData?.order?.ordersType !== "DELIVERY" ? (
                                            <Typography className="text">Pick Up</Typography>
                                        ) : (
                                            <Typography className="text">Delivery</Typography>
                                        )}
                                        </Box>
                                        {orderDetailData?.order?.ordersType !== "DELIVERY" ? ( <Typography className="time">{orderDetailData?.order?.readyTime} Pick Up</Typography> ) : ( <Typography className="time">Making in {orderDetailData?.order?.totalMakingTime}</Typography> )}
                                    </Box>
                                    <Typography className="secondBox">{orderDetailData?.order?.outlet?.name}, {orderDetailData?.order?.outlet?.city}, {orderDetailData?.order?.outlet?.state}</Typography>
                                </Box>
                            </Grid>
                            <Grid item md={6}>
                                <Box className="orderFinalSummary">
                                    <Box className="orderSummaryTable">
                                        <Typography className="head">Order Summary</Typography>
                                        <Box className="row">
                                            <Typography className="itemHead">Item(s)</Typography>
                                            <Typography className="itemValue">{totalQuantityInOrder}</Typography>
                                        </Box>
                                        <Box className="row">
                                            <Typography className="itemHead">Item(s) Total</Typography>
                                            <Typography className="itemValue"><span style={{ fontSize: '14px', textDecoration: 'line-through', color: 'gray' }}>₹{orderDetailData?.order?.grandTotal?.toFixed(2).replace(/\.?0*$/, '')}</span> ₹{orderDetailData?.order?.totalTaxable?.toFixed(2).replace(/\.?0*$/, '')}</Typography>
                                        </Box>  
                                        <Box className="row">
                                            <Typography className="itemHead">Taxable</Typography>
                                            <Typography className="itemValue">₹{orderDetailData?.order?.totalTaxable?.toFixed(2).replace(/\.?0*$/, '')}</Typography>
                                        </Box>  
                                        <Box className="row">
                                            <Typography className="itemHead" sx={{ display: 'flex', alignItems: 'center'}}>Tax
                                                <ClickAwayListener ClickAwayListener onClickAway={handleTaxTooltipClose}>
                                                <Tooltip
                                                    PopperProps={{ disablePortal: true, }}
                                                    onClose={handleTaxTooltipClose}
                                                    open={openTaxTooltip}
                                                    disableFocusListener
                                                    disableHoverListener
                                                    disableTouchListener
                                                    title={taxTooltipContent}
                                                >
                                                    <IconButton onClick={handleTaxTooltipOpen} size="small" sx={{ padding: 0, marginLeft: '5px' }}>
                                                        <InfoOutlined sx={{ fontSize: '12px' }} />
                                                    </IconButton>
                                                </Tooltip>
                                                </ClickAwayListener>
                                            </Typography>
                                            <Typography className="itemValue">₹ {totalTaxAmount?.toFixed(2)}</Typography>
                                        </Box>
                                        {/* {orderDetailData?.order?.platformFees > 0 && (
                                            <Box className="row">
                                                <Typography className="itemHead">Platform Fees</Typography>
                                                <Typography className="itemValue">
                                                    {orderDetailData.order.platformFees.toFixed(2) === "0.00" ? "Free" : `₹ ${orderDetailData.order.platformFees.toFixed(2)}`}
                                                </Typography>
                                            </Box>
                                        )} */}
                                        {orderDetailData?.order?.ordersType === 'DELIVERY' && (
                                            <>
                                            {orderDetailData?.order?.packagingCharges > 0 && (
                                                <Box className="row">
                                                <Typography className="itemHead">Packaging Charges</Typography>
                                                <Typography className="itemValue">
                                                    {orderDetailData.order.packagingCharges.toFixed(2) === "0.00" ? "Free" : `₹ ${orderDetailData.order.packagingCharges.toFixed(2)}`}
                                                </Typography>
                                                </Box>
                                            )}
                                            {orderDetailData?.order?.deliveryCharges > 0 && (
                                                <Box className="row">
                                                <Typography className="itemHead">Shipping</Typography>
                                                <Typography className="itemValue">
                                                    {orderDetailData.order.deliveryCharges.toFixed(2) === "0.00" ? "Free" : `₹ ${orderDetailData.order.deliveryCharges.toFixed(2)}`}
                                                </Typography>
                                                </Box>
                                            )}
                                            </>
                                        )}
                                        {totalCouponDiscount > 0 && (
                                            <Box className="row">
                                                <Typography className="itemHead">Coupon Discount</Typography>
                                                <Typography className="itemValue" sx={{ color: 'green' }}>- ₹{totalCouponDiscount?.toFixed(2)}</Typography>
                                            </Box>
                                        )}
                                        {/* {totalSizeUpgradeAmount > 0 &&
                                            <Box className="row">
                                                <Typography className="itemHead">Coupon Discount</Typography>
                                                <Typography className="itemValue" sx={{ color: 'green' }}>- ₹{totalSizeUpgradeAmount?.toFixed(2)}</Typography>
                                            </Box>
                                        } */}
                                        {orderDetailData?.order?.coupon && totalSizeUpgradeAmount <= 0 && ( <Typography className="itemHead" sx={{ paddingBottom: '4px', color: '#1E9CED'}}>( {orderDetailData?.order?.coupon?.couponCode} )</Typography>)}
                                        <Box className="row" sx={{ borderTop: '1px dashed #707070', borderBottom: '1px solid #707070'}}>
                                            <Typography className="itemHead" sx={{ fontWeight: '600'}}>Total Payable</Typography>
                                            <Typography className="itemValue" sx={{ fontWeight: '600'}}>₹ {orderDetailData?.order?.grandTotal?.toFixed(2).replace(/\.?0*$/, '')}</Typography>
                                        </Box>
                                    </Box>
                                    <Box className="savedBox">
                                        <Typography className="text">You Saved</Typography>
                                        <Typography>₹ {((parseFloat(totalSavings || 0) + parseFloat(orderDetailData?.order?.totalDiscount || 0)).toFixed(2).replace(/\.?0*$/, ''))}</Typography>
                                    </Box>
                                    <Box className="paymentBox">
                                        <Typography className="head">Payment Details</Typography>
                                        <Box className="payDetails">
                                            <Typography className="payDetails">Payment mode: {orderDetailData?.order?.paymentMode}</Typography>
                                            <Typography className="payDetails">Date: {formatDate(orderDetailData?.order?.createdAt)}</Typography>
                                        </Box>
                                        <Typography onClick={handleCloseOrderDetailsModal} className="button">Continue Shopping</Typography>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </Dialog>
        <Dialog sx={{ '& .MuiDialog-container': { '& .MuiPaper-root': { overflow: 'auto', '&::-webkit-scrollbar': { display: 'none' }}}}} open={reviewModal} onClose={handleCloseReviewModal} >
            <Box className="reviewModal" sx={{ outline: 'none' }}>
                <ReviewScreen order={selectedOrder} />
            </Box>
        </Dialog>
        </>
    )
}

export default OrderHistoryScreen;