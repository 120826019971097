import React, { useEffect, useRef, useState } from 'react';
import { Card, Grid, Typography, Box, CircularProgress, useMediaQuery, useTheme } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import ArrowDropUp from '@mui/icons-material/ArrowDropUp';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { setData } from '../store/Cart/cartSlice';
import toast from 'react-hot-toast';
import gsap from 'gsap';
import { API } from '../utils/Api';

const CartItem = ({ item }) => {
  const theme = useTheme();
  const matchesMdUp = useMediaQuery(theme.breakpoints.up('md'));
  const [quantity, setQuantity] = useState(item?.quantity);
  const token = localStorage.getItem("token");
  const dispatch = useDispatch();
  const [updatingQuantity, setUpdatingQuantity] = useState(false);
  const [outletData, setOutletData] = useState(JSON.parse(localStorage.getItem('selectedStoreData')));
  const [countdown, setCountdown] = useState(matchesMdUp ? 5 : 5);
  const [showAddons, setShowAddons] = useState(false);
  const cardRef = useRef();
  const data = useSelector(state => state?.cart?.data);
  const orderType = localStorage.getItem("selectedOrderType");

  // useEffect(() => {
  //   // We consider the item invalid if the outlet ID is NOT in excludedOutlets:
  //   const isInvalidBecauseExcludedOutletsDontMatch =
  //     item?.product?.excludedOutlets?.includes(outletData?._id);
  
  //   if (
  //     item?.product?.isExhausted ||
  //     item?.product?.status === 'INACTIVE' ||
  //     (!item?.product?.isEventProduct && outletData?.isEventOutlet) ||
  //     (item?.product?.isEventProduct && !outletData?.isEventOutlet) ||
  //     isInvalidBecauseExcludedOutletsDontMatch
  //   ) {
  //     // Start countdown to remove
  //     const timer = countdown > 0 && setInterval(() => {
  //       setCountdown((prevCountdown) => prevCountdown - 1);
  //     }, 1000);
  //     return () => clearInterval(timer);
  //   }
  // }, [
  //   countdown,
  //   item?.product?.isExhausted,
  //   item?.product?.status,
  //   item?.product?.isEventProduct,
  //   outletData?.isEventOutlet,
  //   outletData?._id,
  //   item?.product?.excludedOutlets
  // ]);
  
  useEffect(() => {
    if (item?.product?.isExhausted
      || item?.product?.status === 'INACTIVE'
      || (!item?.product?.isEventProduct && outletData?.isEventOutlet)
      || (item?.product?.isEventProduct && !outletData?.isEventOutlet)
      || (item?.product?.excludedOutlets?.includes(outletData?._id))) {
      const timer = countdown > 0 && setInterval(() => {
        setCountdown(prevCountdown => prevCountdown - 1);
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [countdown, item?.product?.isExhausted, item?.product?.status, item?.product?.isEventProduct, outletData?.isEventOutlet, outletData?._id]);

  useEffect(() => {
    if (countdown === 0) {
      gsap.to(cardRef.current, {
        duration: 0.5,
        x: 100,
        opacity: 0,
        onComplete: () => deleteCartItemExhausted(item._id),
      });
    }
  }, [countdown]);

  const handleIncreaseQuantity = async () => {
    const newQuantity = item.quantity + 1;
    // item.quantity = newQuantity;
    // setQuantity(newQuantity);
    await updateQuantityOnServer(newQuantity);
  };

  const handleDecreaseQuantity = async (cartId) => {
    const itemSizeUpgrade = data?.data?.cartItems?.filter(item => item?.sizeUpgradePayload);
    const itemDiscountPrice = data?.data?.cartItems?.filter(item => item?.discountPricePayload);
    const quantityCheck = itemSizeUpgrade[0]?.sizeUpgradePayload?.prevCartItem;
    const quantityCheckItem = itemDiscountPrice.find(item => item?.discountPricePayload?.prevCartItem === cartId);
    const quantityCheck1 = quantityCheckItem?.discountPricePayload?.prevCartItem;
    // console.log('Decrease quantity', itemDiscountPrice, quantityCheck1);
    
    if (item.quantity > 1 || (itemSizeUpgrade.length > 0 && quantityCheck === cartId) || (itemDiscountPrice.length > 0 && quantityCheck1 === cartId)) {
      const newQuantity = item.quantity - 1;
      await updateQuantityOnServer(newQuantity);
    } else {
      await deleteCartItem(item._id);
    }
  };

  console.log('User cart data', item?.product?.excludedOutlets, outletData?._id);

  const fetchData = async () => {
    try {
      const response = await axios.get(`${API.BASE_URL}cart/userCart/${outletData._id}/${orderType}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      dispatch(setData(response?.data));
    } catch (error) {
      dispatch(setData([]));
      console.error('Cart Screen Error', error?.response?.data);
    }
  };

  useEffect(() => {
    setOutletData(JSON.parse(localStorage.getItem('selectedStoreData')));
    console.log('item', item);
  }, []);

  const updateQuantityOnServer = async (newQuantity) => {
    try {
      setUpdatingQuantity(true);
      const response = await axios.put(`${API.BASE_URL}cart`, {
        cartItems: [{ _id: item._id, quantity: newQuantity }],
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      toast.success("Item quantity updated in your cart");
      await fetchData();
    } catch (error) {
      console.error(error);
      toast.error(error.response?.data?.error);
    } finally {
      setUpdatingQuantity(false);
    }
  };

  const deleteCartItemExhausted = async (cartItemId) => {
    try {
      setUpdatingQuantity(true);
      const response = await axios.delete(`${API.BASE_URL}cart`, {
        data: { "cartItemIds": [cartItemId] },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      toast.success("Item removed from your cart");
      await fetchData();
    } catch (error) {
      console.error(error);
    } finally {
      setUpdatingQuantity(false);
    }
  };

  const deleteCartItem = async (cartItemId) => {
    try {
      setUpdatingQuantity(true);
      const response = await axios.delete(`${API.BASE_URL}cart`, {
        data: { "cartItemIds": [cartItemId] },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      toast.success("Item removed from your cart");
      await fetchData();
    } catch (error) {
      console.error(error);
    } finally {
      setUpdatingQuantity(false);
    }
  };

  const toggleAddons = () => {
    setShowAddons(prevState => !prevState);
  };

  const calculateTotalPrice = () => {
    let totalPrice = item?.product?.offerPrice * item?.quantity;
    item?.addons?.forEach(addon => {
      totalPrice += addon?.offerPrice * item?.quantity;
    });
    return totalPrice?.toFixed(2).replace(/\.?0*$/, '');
  };

  const calculateTotalPriceForDiscountPayload = () => {
    let totalPrice = item?.product?.offerPrice * item?.quantity;
    item?.addons?.forEach(addon => {
      totalPrice += addon?.offerPrice * item?.quantity;
    });
    return totalPrice?.toFixed(2).replace(/\.?0*$/, '');
  };

  return (
    <Card ref={cardRef} key={item?._id} className={`cartItem ${item?.product?.isExhausted ? 'exhausted' : ''}`}>
      <Grid container>
        <Grid className="productName" item xs={5}>
          <Typography className="name">{item?.product?.name}</Typography>
          <Typography className="addonText">
            {item?.addons?.map((addon, index) => addon?.selectedValue).join(', ')}
          </Typography>
        </Grid>
        {item?.product?.isExhausted || item?.product?.status === 'INACTIVE' || (!item?.product?.isEventProduct && outletData?.isEventOutlet) || (item?.product?.isEventProduct && !outletData?.isEventOutlet) || (item?.product?.excludedOutlets?.includes(outletData?._id)) ? (
          <Grid item xs={7} sx={{ alignSelf: 'center', justifyContent: 'right', paddingRight: '17px' }}>
            <Typography className="outOfStock">Out of Stock</Typography>
            <Typography className="removingText">Removing from cart in - {countdown}s</Typography>
          </Grid>
        ) : (
          <>
            <Grid item xs={4} sx={{ display: 'flex' }}>
              <Box className="addRemoveItem">
                {updatingQuantity || item?.sizeUpgradePayload || item?.discountPricePayload ? (
                  <RemoveCircleIcon sx={{ cursor: 'not-allowed', color: 'grey' }} className="text" />
                ) : (
                  <RemoveCircleIcon sx={{ cursor: 'pointer' }} className="text" onClick={() => handleDecreaseQuantity(item?._id)} />
                )}
                <Typography className="text">{updatingQuantity ? <CircularProgress size={20} /> : item?.quantity}</Typography>
                {updatingQuantity || item?.sizeUpgradePayload || item?.discountPricePayload ? (
                  <AddCircleIcon sx={{ cursor: 'not-allowed', color: 'grey' }} className="text" />
                ) : (
                  <AddCircleIcon sx={{ cursor: 'pointer' }} className="text" onClick={handleIncreaseQuantity} />
                )}
              </Box>
            </Grid>
            <Grid item xs={3} sx={{ display: 'flex', alignSelf: 'center', justifyContent: 'center' }}>
              {item?.discountPricePayload ? (
                <Typography sx={{ display: 'flex', alignItems: 'center' }} className="discountPrice"><span className="strikePrice">₹{calculateTotalPrice()}</span>₹{item?.discountPricePayload?.discountPrice.toFixed(2) * item?.quantity}</Typography>
              ) : (
                <Typography className="price">
                  ₹{calculateTotalPrice()}
                </Typography>
              )}
            </Grid>
            {item?.sizeUpgradePayload && (
              <Grid item xs={12} sx={{ ml: 2, mt: -1, display: 'flex', justifyContent: 'space-between', paddingBottom: '4px' }}>
                <Typography className="couponAppliedText" sx={{ color: 'green', fontSize: '10px' }}>
                  Can't do operation, coupon applied
                </Typography>
                <Box sx={{ border: '2px solid #09A5F7', mr: 3, mt: -1, pl: 1, pr: 1, borderRadius: '7px', borderBottomLeftRadius: '0px', fontSize: '12px', display: 'flex', alignItems: 'center' }}>
                  ₹ {item?.sizeUpgradePayload?.newAddonItemOfferPrice - item?.sizeUpgradePayload?.prevAddonItemOfferPrice} OFF
                </Box>
              </Grid>
            )}
            {item?.discountPricePayload && (
              <Grid item xs={12} sx={{ ml: 2, mt: -1, display: 'flex', justifyContent: 'space-between', paddingBottom: '4px' }}>
                <Typography className="couponAppliedText" sx={{ color: 'green', fontSize: '10px' }}>
                  Can't do operation, coupon applied
                </Typography>
              </Grid>
            )}
          </>
        )}
      </Grid>
    </Card>
  );
};

export default CartItem;
