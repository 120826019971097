import { Box, Typography, useMediaQuery, useTheme, Drawer, IconButton, Grid, InputAdornment, CircularProgress, Stack, Hidden, Modal, TextField, Button } from '@mui/material';
import React, { useEffect, useState } from 'react'
import ScrollCouponComponent from '../../components/ScrollCouponComponent';
import PrimaryButton from '../../components/PrimaryButton';
import axios from 'axios';
import { API } from '../../utils/Api';
import ProductDrawer from '../../components/ProductDrawer';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import FavoriteRoundedIcon from '@mui/icons-material/FavoriteRounded';
import { CloseOutlined, ArrowBack } from '@mui/icons-material';
import Images from '../../utils/Images';
import FavoriteBorderRoundedIcon from '@mui/icons-material/FavoriteBorderRounded';
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AddonIconComponent from '../../components/AddonIconComponent';
import { useDispatch, useSelector } from 'react-redux';
import { setData } from '../../store/Cart/cartSlice';
import { setUser } from '../../store/Profile/UserSlice';
import * as Yup from 'yup';
import AuthModals from '../../components/AuthComponents/AuthModals';

const OfferScreen = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const dispatch = useDispatch();
  const outlet = localStorage.getItem("selectedStoreData");
  const token = localStorage.getItem('token');
  const deliveryType = localStorage.getItem("selectedOrderType");
  const [outletData, setOutletData] = useState(JSON.parse(localStorage.getItem('selectedStoreData')));
  const [clickableAdvertise, setClickableAdvertise] = useState([]);
  const [nonClickableAdvertise, setNonClickableAdvertise] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [open, setopen] = useState(false);
  const [selectedAddons, setSelectedAddons] = useState({});
  const [addingToCart, setAddingToCart] = useState(false);
  const outletObject = JSON.parse(outlet);
  const outletId = outletObject?._id;
  const matchesMdUp = useMediaQuery(theme.breakpoints.up('lg'));
  const [isFavorited, setIsFavorited] = useState(false);
  const [wishlistData, setWishlistData] = useState([]);
  const cartDataRedux = useSelector(state => state?.cart?.data);
  const [cartData, setCartData] = useState([]);
  const matchesMobile = useMediaQuery(theme.breakpoints.up('md'));

  console.log('sijdbcijabsdcjbsdvb', outletData?._id);

  const getWishlist = async () => {
    try {
      const response = await axios.get(`${API.BASE_URL}wishlist/getUserWishlist/${outletData?._id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setWishlistData(response?.data?.data?.wishList_product_Ids);
      console.log('Wishlist in initial Screen Data', response?.data?.data?.wishList_product_Ids);
    } catch (error) {
      console.log('Wishlist Screen Error', error?.response?.data);
    }
  };

  const isProductInWishlist = (productId) => {
    return wishlistData.includes(productId);
  };

  const handleAddonSelect = (addonId, addonValue) => {
    setSelectedAddons((prevSelected) => ({
      ...prevSelected,
      [addonId]: addonValue,
    }));
    // console.log('Addons', { addonId, ...addonValue });
  };

  const handleFavoriteClick = async () => {
    if (token) {
      try {
        if (isProductInWishlist(selectedProduct._id)) {
          const response = await axios.post(
            `${API.BASE_URL}wishlist/remove`,
            { productId: selectedProduct._id },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          setIsFavorited(false);
          toast.success('Product removed from favorites!!!');
          console.log("Wishlist Remove Success", response.data);
          getWishlist();
        } else {
          const response = await axios.post(
            `${API.BASE_URL}wishlist/add`,
            { productId: selectedProduct._id },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          setIsFavorited(true);
          getWishlist();
          toast.success('Product added to favorites!!!');
          console.log("Wishlist Add Success", response.data);
        }
      } catch (error) {
        console.error(error);
      }
    } else {
      if (matchesMobile) {
        // setopen(true);
        handleOpenAuth();
      } else {
        navigate('/mobileNumber');
      }
    }
  };

  const handleProductClick = (product) => {
    setSelectedProduct(product);
    setIsFavorited(isProductInWishlist(product._id));
    console.log('Product', product);
    console.log('Product', product);
    // Set default addons
    const defaultAddons = {};
    product.addons.forEach((addon) => {
      const defaultValue = addon.addonValues.find(value => value.isDefault);
      if (defaultValue) {
        defaultAddons[addon._id] = {
          ...defaultValue,
          offerPrice: defaultValue.offerPrice ?? 0,
          mrp: defaultValue.mrp ?? 0,
        };
      }
    });
    setSelectedAddons(defaultAddons);
  };

  const handleCloseModal = () => {
    setSelectedProduct(null);
    setQuantity(1);
    setSelectedAddons({});
  };

  const handleAddToCart = async () => {
    if (token) {
      setAddingToCart(true);
      try {
        const addonsArray = Object.keys(selectedAddons).map((addonId) => ({
          _id: addonId,
          selectedValue: selectedAddons[addonId].value,
          addonValueId: selectedAddons[addonId]._id,
        }));
        const cartItem = {
          ordersType: deliveryType,
          outlet: outletId,
          cartItems: [
            {
              product: selectedProduct._id,
              quantity: quantity,
              addons: addonsArray.length > 0 ? addonsArray : undefined,
            }
          ],
        };
        const response = await axios.post(`${API.BASE_URL}cart`, cartItem, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        handleCloseModal();
        fetchUserCartData();
        setAddingToCart(false);
        toast.success('Product added to cart!!!');
        console.log(response.data);
      } catch (error) {
        console.error(error);
      } finally {
        setAddingToCart(false);
      }
    } else {
      if (matchesMdUp) {
        setopen(true);
      } else {
        navigate('/mobileNumber');
      }
    }
  };

  const fetchUserCartData = async () => {
    try {
      const response = await axios.get(`${API.BASE_URL}cart/userCart/${outletData._id}/${deliveryType}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      dispatch(setData(response?.data));
      setCartData(cartDataRedux);
      // console.log('cartDataRedux', cartDataRedux);
      console.log('Cart Screen Data', response?.data?.data?.cartItems);
    } catch (error) {
      dispatch(setData([]));
      console.log('Cart Screen Error', error?.response?.data);
    }
  };

  const getAdvertisments = async () => {
    const url = outletData && outletData._id
      ? `${API.BASE_URL}advertisement/user?outletId=${outletData._id}`
      : `${API.BASE_URL}advertisement/user`;
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log('Clickable Ads', response.data.data.advertisements.clickableAdvertisements);
      setNonClickableAdvertise(response?.data?.data?.advertisements?.nonClickableAdvertisements);
      setClickableAdvertise(response?.data?.data?.advertisements?.clickableAdvertisements);
    } catch (error) {
      console.log("Error in getting Ads", error?.response);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getAdvertisments();
    getWishlist();
  }, []);

  const handleIncreaseQuantity = () => {
    setQuantity((prevQuantity) => prevQuantity + 1);
  };

  const handleDecreaseQuantity = () => {
    if (quantity > 1) {
      setQuantity((prevQuantity) => prevQuantity - 1);
    }
  };

  const [totalPrice, setTotalPrice] = useState(selectedProduct?.offerPrice || 0);
  const [totalMrpPrice, setTotalMrpPrice] = useState(selectedProduct?.mrp || 0);
  const [offerPercentage, setOfferPercentage] = useState(0);

  useEffect(() => {
    let calculateTotalPrice = () => {
      let price = selectedProduct?.offerPrice ?? 0;
      let mrp = selectedProduct?.mrp ?? 0;
      Object.values(selectedAddons).forEach(addon => {
        price += addon.offerPrice ?? 0;
        mrp += addon.mrp ?? 0;
      });
      setTotalPrice(price);
      setTotalMrpPrice(mrp);
    };
    calculateTotalPrice();
  }, [selectedAddons, selectedProduct]);

  useEffect(() => {
    if (totalMrpPrice > 0 && totalPrice < totalMrpPrice) {
      const discount = ((totalMrpPrice - totalPrice) / totalMrpPrice) * 100;
      setOfferPercentage(discount);
    } else {
      setOfferPercentage(0);
    }
  }, [totalPrice, totalMrpPrice]);

  const [isModalOpen, setModalOpen] = useState(false);

  const handleOpenAuth = () => {
    setModalOpen(true);
  }

  const handleCloseAuth = () => {
    setModalOpen(false);
  }

  return (
    <>
      <Box className="offerScreen">
        <Hidden mdUp>
          <Box className="title">
            <Typography variant='h6' className="titleText">Offers</Typography>
          </Box>
        </Hidden>
        <>
          {nonClickableAdvertise?.filter(ad => {
            const isNotTopOrBottomImage = !ad?.isTopImage && !ad.isBottomImage;
            const today = new Date().toISOString();
            // const excludedOutlets = ad?.excludedOutlets;
            const isValidDate =
              (!ad?.validityStartDate || ad?.validityStartDate <= today) &&
              (!ad?.validityEndDate || ad?.validityEndDate >= today);
            const isNotExcluded = !ad?.excludedOutlets?.includes(outletData?._id);
            return isNotTopOrBottomImage && isValidDate && isNotExcluded;
          })
            .map((ad, index) => (
              <div className='adImageBox' key={index}>
                <img loading='lazy' className="adImage" onClick={() => navigate('/productsListing')} src={ad.adImagePath} alt={`Advertisement ${index}`} />
              </div>
            ))}

          {/* {clickableAdvertise?.filter(ad => outletData?.isEventOutlet || !ad?.product?.isEventProduct).map((ad, index) => ( */}
          {clickableAdvertise
            .filter(ad => {
              const isValidOutlet = outletData?.isEventOutlet || !ad?.product?.isEventProduct;
              const today = new Date().toISOString();
              const hasValidDates =
                (!ad?.validityStartDate || ad?.validityStartDate <= today) &&
                (!ad?.validityEndDate || ad?.validityEndDate >= today);
              const isActive = ad?.product?.status !== 'INACTIVE';
              const isNotExcluded = !ad?.excludedOutlets?.includes(outletData?._id);
              return isValidOutlet && hasValidDates && isActive && isNotExcluded;
            })
            .map((ad, index) => (
              <div className='adImageBox' key={index}>
                <img loading='lazy' className="adImage" onClick={() => handleProductClick(ad?.product)} src={ad.adImagePath} alt={`Advertisement ${index}`} />
              </div>
            ))}
        </>
      </Box>
      <ProductDrawer
        open={!!selectedProduct}
        onClose={handleCloseModal}
        selectedProduct={selectedProduct}
        handleAddToCart={handleAddToCart}
        isFavorited={isFavorited}
        handleFavoriteClick={handleFavoriteClick}
        quantity={quantity}
        handleIncreaseQuantity={handleIncreaseQuantity}
        handleDecreaseQuantity={handleDecreaseQuantity}
        selectedAddons={selectedAddons}
        handleAddonSelect={handleAddonSelect}
        totalPrice={totalPrice}
        totalMrpPrice={totalMrpPrice}
        offerPercentage={offerPercentage}
        addingToCart={addingToCart}
      />
      <AuthModals openAuth={isModalOpen} handleCloseAuth={handleCloseAuth} />
    </>
  )
}

export default OfferScreen;
