import { Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Images from '../utils/Images';
import axios from 'axios';
import { API } from '../utils/Api';
import PerfectCombo from './PerfectCombo';
import { useNavigate } from 'react-router-dom';

const EmptyCart = () => {
    const [outletData, setOutletData] = useState(JSON.parse(localStorage.getItem('selectedStoreData')));
    const token = localStorage.getItem("token");
    const [categories, setCategories] = useState([]);
    const navigate = useNavigate();

    const getCategoriesList = async () => {
        if (!outletData?._id) {
            return;
        }
        try {
            const response = await axios.get(`${API.BASE_URL}masters/category/getCategoryWithProducts/${outletData._id}`, {
                headers: token
                    ? {
                        Authorization: `Bearer ${token}`,
                    }
                    : {},
            });
            let fetchedCategories = response?.data?.data?.categories;

            if (outletData?.isEventOutlet) {
                fetchedCategories = fetchedCategories.sort((a, b) => {
                    return (b.isEventCategory ? 1 : 0) - (a.isEventCategory ? 1 : 0);
                });
            }
            setCategories(response?.data?.data?.categories);
        } catch (error) {
            console.error('Error:', error);
        } finally {
        }
    };

    useEffect(() => {
        getCategoriesList();
    }, []);

    return (
        <Box className="emptyCart">
            <img className="birdImage" src={Images.emptyCartImage} />
            <Box className="Box">
                <Box className="imageCategories" sx={{ paddingBottom: '18px' }}>
                    <Box sx={{ textAlign: 'center', borderBottom: '1px solid #DFEEF4', margin: '5px 20px 10px 20px', paddingBottom: '7px' }}>
                        <Typography variant='h6' sx={{ padding: '0px', margin: '0px' }}>Your Xpanse Cart Is Empty</Typography>
                        <Typography variant='caption' sx={{ padding: '0px', margin: '0px', color: '#626262' }}>Your cart feels lonely. Give it some company will you?</Typography>
                    </Box>
                    <Typography variant='caption' sx={{ textAlign: 'left', padding: '0 0px 7px 10px' }}>Choose a category</Typography>
                    <Box className="imageCategoryContainer">
                        {categories?.slice().sort((a, b) => {
                            // Prioritize isEventCategory categories and then sort by sequenceNumber
                            if (outletData.isEventOutlet) {
                                return (b.isEventCategory ? 1 : 0) - (a.isEventCategory ? 1 : 0) || a.sequenceNumber - b.sequenceNumber;
                            }
                            // If isEventOutlet is false, just sort by sequenceNumber
                            return a.sequenceNumber - b.sequenceNumber;
                        }).filter(category => outletData.isEventOutlet || !category.isEventCategory).map((category, index) => (
                            <Box onClick={() => navigate('/productsListing')} className="img-grid" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <Box key={category?._id} className="imageBox">
                                    <img className="image" src={category?.imageURL} alt="product" />
                                </Box>
                                <Typography className="categoryName">{category?.name}</Typography>
                            </Box>
                        ))}
                    </Box>
                </Box>
                <PerfectCombo />
            </Box>
        </Box>
    )
}

export default EmptyCart;
