import React, { useState, useEffect, useRef } from 'react';
import { Box, Grid, Stack, Typography, Dialog, Drawer, Button, Hidden, useTheme, useMediaQuery, IconButton, CircularProgress } from '@mui/material';
import Images from '../../utils/Images';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProducts } from '../../store/Products/productsSlice';
import axios from 'axios';
import { API } from '../../utils/Api';
import OrderAgain from '../../components/OrderAgain';
import FavoriteRoundedIcon from '@mui/icons-material/FavoriteRounded';
import FavoriteBorderRoundedIcon from '@mui/icons-material/FavoriteBorderRounded';
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { SearchBar } from '../../components/SearchBar';
import StoreHeader from '../../components/StoreHeader';
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import { setData } from '../../store/Cart/cartSlice';
import FavouriteProducts from '../../components/FavouriteProducts';
import { CloseOutlined, KeyboardArrowRight } from '@mui/icons-material';
import AddonIconComponent from '../../components/AddonIconComponent';
import ArrivingNowOrders from '../../components/ArrivingNowOrders';
import ProductListingSkeleton from '../../components/SkeletonComponents/ProductListingSkeleton';
import AuthModals from '../../components/AuthComponents/AuthModals';
import ProductDrawer from '../../components/ProductDrawer';
import { Player } from '@lottiefiles/react-lottie-player';
import FloatingCart from '../../components/FloatingCart';
import SortByComponent from '../../components/SortByComponent';
import ProductCard from '../../components/ProductCard';

const ProductsListing = () => {
  const [showBox, setShowBox] = useState(false);
  const [loginModalOpen, setLoginModalOpen] = useState(false);
  const dispatch = useDispatch();
  // const products = useSelector((state) => state.products.products);
  // const categories = useSelector((state) => state.products.categories);
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  // const status = useSelector((state) => state.products.status);
  // const error = useSelector((state) => state.products.error);
  const [activeCategory, setActiveCategory] = useState(categories[0]?._id);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const outlet = localStorage.getItem("selectedStoreData");
  const deliveryType = localStorage.getItem("selectedOrderType");
  const token = localStorage.getItem("token");
  const outletObject = JSON.parse(outlet);
  const outletId = outletObject?._id;
  const [isFavorited, setIsFavorited] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const [cartData, setCartData] = useState([]);
  // const [searchText, setSearchText] = useState('');
  const navigate = useNavigate();
  // const data = useSelector(state => state.cart.data);
  // const [data, setData] = useState(null);
  // const { searchText } = useSearch();
  const [wishlistData, setWishlistData] = useState([]);
  // const cartDataRedux = useSelector((state) => state.setData);
  const cartDataRedux = useSelector(state => state?.cart?.data);
  const searchText = useSelector(state => state.search.searchText);
  const [outletData, setOutletData] = useState(JSON.parse(localStorage.getItem('selectedStoreData')));
  const [isDataAvailable, setIsDataAvailable] = useState(false);
  const theme = useTheme();
  const matchesMdUp = useMediaQuery(theme.breakpoints.up('md'));
  const [selectedAddons, setSelectedAddons] = useState({});
  const [addingToCart, setAddingToCart] = useState(false);
  const [fetchingProducts, setFetchingProducts] = useState(true);
  const matchesMobile = useMediaQuery(theme.breakpoints.up('md'));
  const [activeFilter, setActiveFilter] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleCategoryClick = (categoryId, index) => {
    setActiveCategory(categoryId);
    dispatch(fetchProducts(categoryId));
    console.log("Products after dispatching fetchProducts:", products);
    scrollToIndex(index);
  };

  useEffect(() => {
    fetchUserCartData();
    const shimmerTimeout = setTimeout(() => {
      setShowBox(true);
    }, 3000);
    return () => clearTimeout(shimmerTimeout);
  }, []);

  const handleProductClick = (product) => {
    setSelectedProduct(product);
    console.log('Product', product);
  };

  const handleCloseModal = () => {
    setSelectedProduct(null);
    setQuantity(1);
    setSelectedAddons({});
  };

  const handleIncreaseQuantity = () => {
    setQuantity((prevQuantity) => prevQuantity + 1);
  };

  const handleDecreaseQuantity = () => {
    if (quantity > 1) {
      setQuantity((prevQuantity) => prevQuantity - 1);
    }
  };

  const handleAddonSelect = (addonId, addonValue) => {
    setSelectedAddons((prevSelectedAddons) => {
      const isSelected = prevSelectedAddons[addonId]?._id === addonValue._id;
      const isDefault = addonValue.isDefault;
      if (isSelected && !isDefault) {
        const updatedAddons = { ...prevSelectedAddons };
        delete updatedAddons[addonId];
        return updatedAddons;
      }
      return {
        ...prevSelectedAddons,
        [addonId]: addonValue,
      };
    });
  };

  useEffect(() => {
    if (selectedProduct?.hasAddon && selectedProduct?.addons && selectedProduct?.addons?.length > 0) {
      const defaultAddons = {};
      selectedProduct?.addons?.forEach((addon) => {
        const defaultAddonValue = addon?.addonValues?.find((value) => value?.isDefault);
        if (defaultAddonValue) {
          defaultAddons[addon._id] = defaultAddonValue;
        }
      });
      setSelectedAddons(defaultAddons);
    }
  }, [selectedProduct]);

  const handleAddToCart = async () => {
    if (token) {
      setAddingToCart(true);
      try {
        const addonsArray = Object.keys(selectedAddons).map((addonId) => ({
          _id: addonId,
          selectedValue: selectedAddons[addonId].value,
          addonValueId: selectedAddons[addonId]._id,
        }));
        const cartItem = {
          ordersType: deliveryType,
          outlet: outletId,
          cartItems: [
            {
              product: selectedProduct._id,
              quantity: quantity,
              addons: addonsArray.length > 0 ? addonsArray : undefined,
            }
          ],
        };
        const response = await axios.post(`${API.BASE_URL}cart`, cartItem, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        handleCloseModal();
        fetchUserCartData();
        setAddingToCart(false);
        toast.success('Product added to cart!!!');
        console.log(response.data);
      } catch (error) {
        console.error(error);
      } finally {
        setAddingToCart(false);
      }
    } else {
      if (matchesMdUp) {
        // setopen(true);
        handleOpenAuth();
      } else {
        navigate('/mobileNumber');
      }
    }
  };

  const fetchUserCartData = async () => {
    if (!token || !outletData?._id) {
      return;
    }
    try {
      const response = await axios.get(`${API.BASE_URL}cart/userCart/${outletData._id}/${deliveryType}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      dispatch(setData(response?.data));
      setCartData(cartDataRedux);
      // console.log('cartDataRedux', cartDataRedux);
      console.log('Cart Screen Data', response?.data?.data?.cartItems);
    } catch (error) {
      dispatch(setData([]));
      console.log('Cart Screen Error', error?.response?.data);
    }
  };

  const getCategoriesList = async () => {
    setFetchingProducts(true);
    if (!outletData?._id) {
      return;
    }
    try {
      const response = await axios.get(`${API.BASE_URL}masters/category/getCategoryWithProducts/${outletData._id}`, {
        headers: token
          ? {
            Authorization: `Bearer ${token}`,
          }
          : {},
        params: {
          search: searchText,
          foodType: activeFilter
        }
      });
      let fetchedCategories = response?.data?.data?.categories;

      // Sort categories if isEventOutlet is true
      if (outletData?.isEventOutlet) {
        fetchedCategories = fetchedCategories.sort((a, b) => {
          return (b.isEventCategory ? 1 : 0) - (a.isEventCategory ? 1 : 0);
        });
      }
      setCategories(response?.data?.data?.categories);
      setFetchingProducts(false);
      // console.log('Outlet data', outletData.isEventOutlet);
      // setProducts(response.data.data.products);
      // console.log('Categories fetched successfully in the screen:', response?.data?.data?.categories);
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setFetchingProducts(false);
    }
  };

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        getCategoriesList();
      }
    };
    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    let timeoutId;
    const checkDataAvailability = () => {
      if (outletData) {
        setIsDataAvailable(true);
        clearTimeout(timeoutId);
        getCategoriesList();
      } else {
        timeoutId = setTimeout(checkDataAvailability, 500);
        setOutletData(JSON.parse(localStorage.getItem('selectedStoreData')));
      }
    };
    checkDataAvailability();
    return () => {
      clearTimeout(timeoutId);
    };
  }, [outletData]);

  useEffect(() => {
    // getProductsList(activeCategory);
    getCategoriesList();
  }, [searchText, activeFilter]);

  useEffect(() => {
    fetchUserCartData();
    getCategoriesList();
    // getProductsList(activeCategory);
  }, []);

  const getProductQuantityInCart = (productId) => {
    let totalQuantity = 0;
    cartDataRedux?.data?.cartItems?.forEach(item => { if (item.product._id === productId) { totalQuantity += item.quantity; } });
    return totalQuantity;
  };

  const containerRef = useRef(null);

  const scrollToIndex = (index) => {
    const container = containerRef.current;
    const element = container.childNodes[index];
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  const getRandomRating = () => {
    return (Math.random() * (4.2 - 3.7) + 3.7).toFixed(1);
  };

  const [totalPrice, setTotalPrice] = useState(selectedProduct?.offerPrice || 0);
  const [totalMrpPrice, setTotalMrpPrice] = useState(selectedProduct?.mrp || 0);
  const [offerPercentage, setOfferPercentage] = useState(0);

  useEffect(() => {
    let calculateTotalPrice = () => {
      let price = selectedProduct?.offerPrice ?? 0;
      let mrp = selectedProduct?.mrp ?? 0;
      Object.values(selectedAddons).forEach(addon => {
        price += addon.offerPrice ?? 0;
        mrp += addon.mrp ?? 0;
      });
      setTotalPrice(price);
      setTotalMrpPrice(mrp);
    };
    calculateTotalPrice();
  }, [selectedAddons, selectedProduct]);

  useEffect(() => {
    if (totalMrpPrice > 0 && totalPrice < totalMrpPrice) {
      const discount = ((totalMrpPrice - totalPrice) / totalMrpPrice) * 100;
      setOfferPercentage(discount);
    } else {
      setOfferPercentage(0);
    }
  }, [totalPrice, totalMrpPrice]);

  const params = useParams();
  const referralId = params['referId'];

  useEffect(() => {
    if (referralId) {
      localStorage.setItem('referralId', referralId);
      console.log("Referral ID stored in localStorage:", referralId);
    }
  }, [referralId]);

  const [isModalOpen, setModalOpen] = useState(false);

  const handleOpenAuth = () => {
    setModalOpen(true);
  }

  const handleCloseAuth = () => {
    setModalOpen(false);
  }

  const handleFilterChange = (value) => {
    setActiveFilter(value);
    console.log('Active filter:', value);
  };

  return (
    <>
      <Hidden mdUp>
        <StoreHeader />
        {/* <SearchBar /> */}
        <ArrivingNowOrders />
        <SortByComponent activeFilter={activeFilter} onFilterChange={handleFilterChange} />
      </Hidden>
      <Box>
        {fetchingProducts ? (
          <ProductListingSkeleton />
        ) : categories?.filter(category =>
          outletData.isEventOutlet || !category.isEventCategory
        ).every(category =>
          category.products.filter(product => outletData.isEventOutlet || !product.isEventProduct).filter(product => product.status !== 'INACTIVE').length === 0
        ) ? (
          <Box sx={{ display: 'flex', flexDirection: 'column', textAlign: 'center' }}>
            <Player autoplay loop={true} keepLastFrame={true} src={Images.noResult} style={{ height: '350px' }} />
            <Typography variant='h6'>Sorry, didn't find what you are looking for...</Typography>
          </Box>
        ) : (
          <React.Fragment>
            <Box className="products-listing">
              <Box className="category-list">
                {categories?.slice().sort((a, b) => {
                  if (outletData.isEventOutlet) {
                    return (b.isEventCategory ? 1 : 0) - (a.isEventCategory ? 1 : 0) || a.sequenceNumber - b.sequenceNumber;
                  }
                  return a.sequenceNumber - b.sequenceNumber;
                }).filter(category => outletData.isEventOutlet || !category.isEventCategory).map((category, index) => (
                  <Box className="d-flex category-items-content" key={category._id}>
                    <Box className={`category-item-highlight-line ${activeCategory === category._id || (index === 0 && !activeCategory) ? 'active' : ''}`}></Box>
                    <Box
                      className={`category-item ${activeCategory === category._id || (index === 0 && !activeCategory) ? 'active' : ''}`}
                      onClick={() => handleCategoryClick(category._id, index)}
                    >
                      {category.name}
                    </Box>
                  </Box>
                ))}
              </Box>
              <Box className="products-container">
                <Grid ref={containerRef} container>
                  {categories.map((category, index) => {
                    let totalProductsRendered = 0;
                    const totalProducts = category?.products?.length;
                    let productsPerRow = 3;
                    const showCategoryTopImage = outletData?.isEventOutlet || (!outletData?.isEventOutlet && !category.isEventCategory);
                    return (
                      <Grid container key={category?._id}>
                        {showCategoryTopImage && (
                          <Grid md={12}>
                            <img className="categoryTopImage" src={category?.bannerImageUrl} alt={category?.name} />
                          </Grid>
                        )}
                        {category?.products.slice().filter(product => outletData.isEventOutlet || !product.isEventProduct).filter(product => product.status !== 'INACTIVE').sort((a, b) => a.sequenceNumber - b.sequenceNumber).map((product, productIndex) => {
                          const rowPosition = Math.floor(productIndex / productsPerRow);
                          const colPosition = productIndex % productsPerRow;
                          const isLastRow = (Math.ceil(totalProducts / productsPerRow) - 1) === rowPosition;
                          const isLastInRow = colPosition === (productsPerRow - 1) || productIndex === (totalProducts - 1);
                          const isFirstInRow = colPosition === 0;
                          return (
                            <>
                              <Grid item sm={12} md={4} key={product._id}>
                                <ProductCard
                                  product={product}
                                  // isFavorited={isProductInWishlist(product?._id)}
                                  quantityInCart={getProductQuantityInCart(product?._id)}
                                  handleProductClick={handleProductClick}
                                  matchesMdUp={matchesMdUp}
                                  isLastInRow={colPosition === productsPerRow - 1 || productIndex === totalProducts - 1}
                                  isLastRow={Math.ceil(totalProducts / productsPerRow) - 1 === rowPosition}
                                  coupon={product?.bestCoupon}
                                />
                              </Grid>
                            </>);
                        })}
                      </Grid>
                    )
                  })}
                </Grid>
              </Box>
              <ProductDrawer
                open={!!selectedProduct}
                onClose={handleCloseModal}
                selectedProduct={selectedProduct}
                handleAddToCart={handleAddToCart}
                quantity={quantity}
                handleIncreaseQuantity={handleIncreaseQuantity}
                handleDecreaseQuantity={handleDecreaseQuantity}
                selectedAddons={selectedAddons}
                handleAddonSelect={handleAddonSelect}
                totalPrice={totalPrice}
                totalMrpPrice={totalMrpPrice}
                offerPercentage={offerPercentage}
                addingToCart={addingToCart}
              />
              <Dialog open={loginModalOpen} onClose={() => setLoginModalOpen(false)}>
                <Box p={3}>
                  <Typography variant="h5">Login Required</Typography>
                  <Typography>Please login to use this feature.</Typography>
                  <Button onClick={() => setLoginModalOpen(false)}>Close</Button>
                  <Hidden mdUp>
                    <Button onClick={() => navigate('/mobileNumber')}>Login Now</Button>
                  </Hidden>
                </Box>
              </Dialog>
            </Box>

            {/* <Box className="product-listing-curve">
              <Box className="product-listing-curve-inside-box"></Box>
            </Box> */}
            <Box className="product-listing-line"></Box>
          </React.Fragment>
        )}
      </Box>
      <AuthModals openAuth={isModalOpen} handleCloseAuth={handleCloseAuth} />
    </>
  );
};

export default ProductsListing;