import { Box, Drawer, Grid, IconButton, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import Images from '../utils/Images';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { API } from '../utils/Api';
import { setData } from '../store/Cart/cartSlice';
import toast from 'react-hot-toast';
import AddonIconComponent from './AddonIconComponent';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import FavoriteRoundedIcon from '@mui/icons-material/FavoriteRounded';
import FavoriteBorderRoundedIcon from '@mui/icons-material/FavoriteBorderRounded';
import { CloseOutlined } from '@mui/icons-material';
import ProductDrawer from './ProductDrawer';

const PerfectCombo = () => {
  const token = localStorage.getItem('token');
  const [products, setProducts] = useState([]);
  const outlet = localStorage.getItem("selectedStoreData");
  const deliveryType = localStorage.getItem("selectedOrderType");
  const outletObject = JSON.parse(outlet);
  const outletId = outletObject?._id;
  const dispatch = useDispatch();
  const [outletData, setOutletData] = useState(JSON.parse(localStorage.getItem('selectedStoreData')));
  const [quantity, setQuantity] = useState(1);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [isFavorited, setIsFavorited] = useState(false);
  const [selectedAddons, setSelectedAddons] = useState({});
  const [wishlistData, setWishlistData] = useState([]);

  const handleCloseModal = () => {
    setSelectedProduct(null);
    setQuantity(1);
    setSelectedAddons({});
  };

  const getPerfectComboProducts = async () => {
    try {
      const response = await axios.get(`${API.BASE_URL}order/suggested/${outletData._id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setProducts(response?.data?.data?.suggestedProducts);
    } catch (error) {
      console.log('Combo Suggested Products Error API Response', error.response);
    }
  };

  useEffect(() => {
    getPerfectComboProducts();
    setOutletData(JSON.parse(localStorage.getItem('selectedStoreData')));
  }, []);

  const fetchUserCartData = async () => {
    try {
      const response = await axios.get(`${API.BASE_URL}cart/userCart/${outletData._id}/${deliveryType}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      dispatch(setData(response?.data));
      // console.log('Cart Screen Data', response?.data);
    } catch (error) {
      dispatch(setData([]));
      // console.log('Cart Screen Error', error?.response?.data);
    }
  };

  const handleAddToCart = async () => {
    try {
      const addonsArray = Object.keys(selectedAddons).map((addonId) => ({
        _id: addonId,
        selectedValue: selectedAddons[addonId].value,
        addonValueId: selectedAddons[addonId]._id,
      }));
      const cartItem = {
        ordersType: deliveryType,
        outlet: outletId,
        cartItems: [
          {
            product: selectedProduct._id,
            quantity: quantity,
            addons: addonsArray.length > 0 ? addonsArray : undefined,
          }
        ],
      };
      const response = await axios.post(`${API.BASE_URL}cart`, cartItem, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      handleCloseModal();
      fetchUserCartData();
      toast.success('Product added to cart!!!');
      console.log(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleAddonSelect = (addonId, addonValue) => {
    setSelectedAddons((prevSelected) => ({
      ...prevSelected,
      [addonId]: addonValue,
    }));
  };

  const getRandomRating = () => {
    return (Math.random() * (4.2 - 3.7) + 3.7).toFixed(1);
  };

  const isProductInWishlist = (productId) => {
    return wishlistData.includes(productId);
  };

  const handleProductClick = (product) => {
    setSelectedProduct(product);
  };

  const [totalPrice, setTotalPrice] = useState(selectedProduct?.offerPrice || 0);
  const [totalMrpPrice, setTotalMrpPrice] = useState(selectedProduct?.mrp || 0);
  const [offerPercentage, setOfferPercentage] = useState(0);

  useEffect(() => {
    const calculateTotalPrice = () => {
      let price = selectedProduct?.offerPrice || 0;
      let mrp = selectedProduct?.mrp || 0;
      Object.values(selectedAddons).forEach(addon => {
        price += addon.offerPrice;
        mrp += addon.mrp;
      });
      setTotalPrice(price);
      setTotalMrpPrice(mrp);
    };
    calculateTotalPrice();
  }, [selectedAddons, selectedProduct]);

  useEffect(() => {
    if (totalMrpPrice > 0 && totalPrice < totalMrpPrice) {
      const discount = ((totalMrpPrice - totalPrice) / totalMrpPrice) * 100;
      setOfferPercentage(discount);
    } else {
      setOfferPercentage(0);
    }
  }, [totalPrice, totalMrpPrice]);

  const handleIncreaseQuantity = () => {
    setQuantity((prevQuantity) => prevQuantity + 1);
  };

  const handleDecreaseQuantity = () => {
    if (quantity > 1) {
      setQuantity((prevQuantity) => prevQuantity - 1);
    }
  };

  const handleFavoriteClick = async () => {
    try {
      if (isProductInWishlist(selectedProduct._id)) {
        const response = await axios.post(
          `${API.BASE_URL}wishlist/remove`,
          { productId: selectedProduct._id },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setIsFavorited(false);
        toast.success('Product removed from favorites!!!');
        console.log("Wishlist Remove Success", response.data);
      } else {
        const response = await axios.post(
          `${API.BASE_URL}wishlist/add`,
          { productId: selectedProduct._id },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setIsFavorited(true);
        toast.success('Product added to favorites!!!');
        console.log("Wishlist Add Success", response.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    products && products.length > 0 ? (
      <Box className="perfectCombo">
        <Typography className="title">Want To Make The Perfect Combo?</Typography>
        <Box className="perfectComboContainer">
          {products.map((product, index) => (
            <Box key={product?.product?._id} className="item">
              <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                <Box className="imageBox">
                  <img className="image" src={product?.product?.displayImageUrl} />
                </Box>
              </Box>
              <Box className="productDetail">
                <Typography className="productName">{product?.product?.name}</Typography>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Box className="priceBox" sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography className="offerPrice">₹{product?.product?.offerPrice}</Typography>
                    <Typography className="mrp">₹{product?.product?.mrp}</Typography>
                  </Box>
                  <Box className="percentOff">
                    {product?.product?.offerPercentage?.toFixed(0)}% OFF
                  </Box>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Box className="quickAddBtn" onClick={() => !product?.product?.isExhausted && handleProductClick(product?.product)}>
                    <Typography>Quick Add</Typography>
                    <AddCircleIcon />
                  </Box>
                </Box>
              </Box>
            </Box>
          ))}
        </Box>
        <ProductDrawer
          open={!!selectedProduct}
          onClose={handleCloseModal}
          selectedProduct={selectedProduct}
          handleAddToCart={handleAddToCart}
          isFavorited={isFavorited}
          handleFavoriteClick={handleFavoriteClick}
          quantity={quantity}
          handleIncreaseQuantity={handleIncreaseQuantity}
          handleDecreaseQuantity={handleDecreaseQuantity}
          selectedAddons={selectedAddons}
          handleAddonSelect={handleAddonSelect}
          totalPrice={totalPrice}
          totalMrpPrice={totalMrpPrice}
          offerPercentage={offerPercentage}
        // addingToCart={addingToCart}
        />
      </Box>
    ) : null
  )
}

export default PerfectCombo;

{/* <Drawer
              open={!!selectedProduct}
              onClose={handleCloseModal}
              anchor="bottom"
            >
              <Box className="productDrawer">
                <IconButton
                  onClick={handleCloseModal}
                  sx={{ position: 'absolute', top: -20, right: 8, zIndex: 1, backgroundColor: '#fff', height: '16px', width: '16px' }}
                >
                  <CloseOutlined sx={{ fontSize: '12px' }} />
                </IconButton>
                <Grid container>
                  <Grid item xs={5} sx={{ justifyContent: 'center'}}>
                    <Box className="imageBox">
                      <img className="image" src={selectedProduct?.displayImageUrl} alt="Product" />
                    </Box>
                  </Grid>
                  <Grid item xs={7} sx={{ paddingLeft: '2%'}}>
                    <Box className="drawerVegAndFavourite">
                      <Box sx={{ display: 'flex' }}>
                        <Typography className="productRating" sx={{ textAlign: 'right'}}><StarRoundedIcon className="star"/>{selectedProduct?.productRatings?.averageProductRatings ? selectedProduct.productRatings.averageProductRatings.toFixed(1) : getRandomRating()}</Typography>
                        <img className='img-fluid vegNonveg' src={selectedProduct?.foodType === 'VEG' ? Images.veg : (selectedProduct?.foodType === 'VEGAN' ? Images.vegan : Images.nonVeg)} alt={selectedProduct?.foodType === 'VEG' ? 'veg' : (selectedProduct?.foodType === 'VEGAN' ? 'vegan' : 'non-veg')} />
                      </Box>
                      {isFavorited ? ( <FavoriteRoundedIcon className="favouriteMarked" onClick={handleFavoriteClick} /> ) : ( <FavoriteBorderRoundedIcon className="favouriteMarked" onClick={handleFavoriteClick} /> )}
                    </Box>
                    <Box className="productDetails">
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography className="productName" sx={{ textAlign: 'left'}}>{selectedProduct?.name}</Typography>
                      </Box>
                      <Typography className="productInfo" sx={{ paddingBottom: '0px' }}>{selectedProduct?.calories} cal {selectedProduct?.weight}<span style={{ textTransform: 'capitalize' }}>{selectedProduct?.weightUnit}</span> </Typography>
                      <Typography className="productInfo">{selectedProduct?.description}</Typography>
                    </Box> 
                  </Grid>
                </Grid>
                {selectedProduct && (
                  <>
                    {selectedProduct.hasAddon && selectedProduct.addons && selectedProduct.addons.length > 0 && (
                      <Box className="addonsBox">
                        {selectedProduct.addons.map((addon) => (
                          <Grid container key={addon._id} sx={{ borderBottom: '1px solid #B3B3B3', padding: '10px 0 7px 0' }}>
                            <Grid item xs={3}>
                              <Typography className="addonTitle">{addon.addonTitle}</Typography>
                            </Grid>
                            <Grid item xs={9}>
                              <Grid container justifyContent="flex-end">
                                {addon.addonValues.map((addonValue) => {
                                  const isSelected = selectedAddons[addon._id]?._id === addonValue._id || (!selectedAddons[addon._id] && addonValue.isDefault);
                                  const icon = addonValue.iconInfo ? <AddonIconComponent iconInfo={addonValue.iconInfo} selected={isSelected} /> : null;
                                  return (
                                    <Grid item xs={4} key={addonValue._id} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                      <Box 
                                        className="addonItem" 
                                        onClick={() => handleAddonSelect(addon._id, { ...addonValue, offerPrice: addonValue.offerPrice ?? 0, mrp: addonValue.mrp ?? 0 })}  
                                        sx={{ display: 'flex', flexDirection: 'column' }}
                                      >
                                        <Box sx={{ flex: '1 1 auto' }} />
                                          <Typography className="addonValue" sx={{ alignSelf: 'center', color: '#00A4E2' }}> {addonValue.subLabel} </Typography>
                                          {icon ? (
                                            <Box className="addonIcon" sx={{ margin: 0, padding: 0 }}> {icon} </Box>
                                          ) : (
                                            <Box className={`addonSelect ${isSelected ? 'selected' : ''}`} sx={{ alignSelf: 'center' }}> {addonValue.value ?? 0} </Box>
                                          )}
                                          <Typography className="addonValue" sx={{ alignSelf: 'center', color: '#118E06' }}> +₹{addonValue.offerPrice ?? 0} </Typography>
                                        </Box>
                                    </Grid>
                                  );
                                })}
                              </Grid>
                            </Grid>
                          </Grid>
                        ))}
                      </Box>
                    )}
                  </>
                )}
                <Box className="priceAndButton">
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography className="productPrice">₹{parseFloat(totalPrice) * parseInt(quantity)}<span className='productMrp'> ₹{parseFloat(totalMrpPrice) * parseInt(quantity)}</span></Typography>
                    <Typography className="productOff">{offerPercentage?.toFixed(0)}% OFF</Typography>
                  </Box>
                  <Box className="addRemoveItem">
                    <RemoveCircleIcon className="text" onClick={handleDecreaseQuantity} />
                    <Typography sx={{ color: '#000' }} className="text">{quantity}</Typography>
                    <AddCircleIcon className="text" onClick={handleIncreaseQuantity} />
                  </Box>
                </Box>
                <Box className={selectedProduct?.isExhausted ? "exhaustedButton" : "button"} onClick={!selectedProduct?.isExhausted ? () => handleAddToCart() : null}>{selectedProduct?.isExhausted ? "Available in 24 hours" : "ADD"}</Box>
              </Box>
            </Drawer> */}
